import 'isomorphic-fetch';
import StoryblokClient from 'storyblok-js-client';
import { PageStoryInterface } from '~/types/content';
import { findStoryblokObjectsMatchingTest } from '../../utils/storyblok';

let InnerStoryblok: StoryblokClient;
const Storyblok = () => {
    if (!InnerStoryblok) {
        InnerStoryblok = new StoryblokClient({
            accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
            region: 'us',
        });
    }
    return InnerStoryblok;
};

/**
 * resolve_links only goes one level deep, per Storyblok documentation
 * https://www.storyblok.com/docs/api/content-delivery/v2#core-resources/stories/retrieve-one-story
 *
 * so in the case where we use resolve_relations to load a story and that story contains an internal link we need
 * (for example a GlobalVerticalCta whose GlobalCtaLink has an internal link), that link will not be resolved and
 * we won't have the information needed to populate the 'to' or 'href' of the rendered link.
 *
 * this function finds those unresolved links, retrieves their stories, and modifies the given story data to put the
 * retrieved stories inside the link objects, just as they would be if resolve_links was able to go two levels deep.
 *
 * NOTE: to reduce complexity, this function mutates the provided story object.
 */
export async function resolveSecondLevelLinks(story: PageStoryInterface, isPreview: boolean) {
    // find link objects that are a story reference for which the story data was not resolved
    // set the max depth to approximate "two levels deep" so it will only find links needed
    // by this article, not by resolved/linked articles (which would be 3-4 levels deep)
    const unresolvedLinks = findStoryblokObjectsMatchingTest(
        story,
        (data: any) => data.linktype === 'story' && !data.story,
        0,
        9,
    );

    // gather (and dedupe) the ids of the stories we need to retrieve
    const ids = Object.keys(
        unresolvedLinks
            .filter((item) => item.id)
            .reduce((result, item) => {
                // eslint-disable-next-line no-param-reassign
                result[item.id] = true;
                return result;
            }, {}),
    );

    const { data } = await Storyblok().get('cdn/stories', {
        by_uuids: ids.join(','),
        per_page: 100,
        version: isPreview ? 'draft' : 'published',
    });

    if (data?.stories?.length) {
        unresolvedLinks.forEach((link) => {
            data.stories.forEach((retrievedStory: PageStoryInterface) => {
                if (link.id === retrievedStory.uuid) {
                    // eslint-disable-next-line no-param-reassign
                    link.story = retrievedStory;
                }
            });
        });
    }
}

export async function getStoryData(
    slug: string,
    resolveLinksType: 'url' | 'story' | 'link',
    resolveRelations: Array<string>,
    isPreview: boolean,
    shouldResolveSecondLevelLinks: boolean,
): Promise<PageStoryInterface> {
    const response = await Storyblok().get(`cdn/stories${slug}`, {
        token: process.env.STORYBLOK_ACCESS_TOKEN,
        resolve_relations: resolveRelations,
        resolve_links: resolveLinksType,
        version: isPreview ? 'draft' : 'published',
    });
    const { story } = response.data;
    if (shouldResolveSecondLevelLinks) {
        await resolveSecondLevelLinks(story, isPreview);
    }
    return story;
}

export async function fetchDatasourceEntry(datasourceName: string): Promise<Record<string, any>> {
    try {
        const { data } = await Storyblok().get('cdn/datasource_entries', {
            datasource: datasourceName,
        });
        const datasourceEntries = data.datasource_entries;
        const datasourceObject = datasourceEntries.reduce((obj: Record<string, any>, item: any) => {
            // Assuming each item has a unique 'name' property that we can use to form the keys of the new object
            // eslint-disable-next-line no-param-reassign
            obj[item.name] = item.value;
            return obj;
        }, {});
        return datasourceObject;
    } catch (error) {
        console.error('Failed to fetch data', error);
        return {};
    }
}
